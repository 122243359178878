/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
//@import "~bootstrap/dist/css/bootstrap.css";
// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Global fonts
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";

// Metronic styles (replace these path when using RTL css below)
@import "./assets/sass/style.angular.scss";

// For RTL, check this documentation https://keenthemes.com/metronic/?page=docs&section=angular-rtl
// @import "./assets/sass/style.angular.rtl.css";

// Default Layout themes
@import "./assets/sass/themes/layout/header/base/light.scss";
@import "./assets/sass/themes/layout/header/menu/light.scss";
@import "./assets/sass/themes/layout/brand/dark.scss";
@import "./assets/sass/themes/layout/aside/dark.scss";

// Header themes
// .header-base-light {
//     @import "./assets/sass/themes/layout/header/base/light.scss";
// }

// .header-base-dark {
//     @import "./assets/sass/themes/layout/header/base/dark.scss";
// }

// Header Menu themes
// .header-menu-light {
//     @import "./assets/sass/themes/layout/header/menu/light.scss";
// }
// .header-menu-dark {
//     @import "./assets/sass/themes/layout/header/menu/dark.scss";
// }

// Brand themes
.brand-dark {
  @import "./assets/sass/themes/layout/brand/dark.scss";
}

.brand-light {
  @import "./assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// .aside-dark {
//   @import "./assets/sass/themes/layout/aside/dark.scss";
// }

// .aside-light {
//   @import "./assets/sass/themes/layout/aside/light.scss";
// }

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

.angular-bootstrap-table {
  overflow-x: auto;
}

.angular-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead th.sortable-active {
    color: #3699ff !important;
  }
}

.cursor-default {
  cursor: default !important;
}

.progress-modal {
  height: 3px;
  width: 100%;
}

.cdk-overlay-container {
  z-index: 2000 !important;
}

.merge-input-group {
  &:focus-within {
    .input-group-text {
      border-color: $primary;
    }
  }

  .input-group-text {
    background: transparent;
    border-radius: 0.42rem 0 0 0.42rem;
    border-right: none !important;
    padding-right: 0;

    &.is-invalid {
      border-color: $danger;
    }
  }

  .form-control {
    border-left: none !important;
  }
}

.visually-hidden {
  display: none;
}

/* -------------------APP FORM changes -----------------*/
// .app-form-group {
//   display: inline-block;
// }

.form-check-input {
  width: 1.3rem;
  height: 1.3rem;
}

.app-na-form-group input {
  width: 1rem;
  height: 1rem;
}

.form-check-label {
  font-weight: 400 !important;
}

.btn-cancel {
  color: $gray-800 !important;
  background-color: $gray-300 !important;
  border-color: $gray-300 !important;
}

html {
  margin: 0;
}

.input-group-transparent {
  .form-control {
    border-right: none !important;
  }

  .form-control-postfix {
    border: 1px solid rgba(147, 158, 170, 0.4);
    border-left: none;
    background: transparent;

    @include border-radius($input-border-radius, 0);

    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  &:has(.form-control:focus) {
    .form-control-postfix {
      border-color: $primary !important;
    }
  }
}

.input-dateTimePicker {
  .ngb-tp {
    width: fit-content;
    margin: auto;
  }
}

// Scroll Bar

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $gray-300;
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px $gray-100;
  background-color: $gray-500;
}

.modal-confirm-size {
  max-width: 420px;
}

.modal-confirm-size-user {
  max-width: 840px;
}

.dashboard-card-text-size {
  font-size: 3.6875rem;
}

.dashboard-card-text-color {
  font-weight: bold;
}

.dashboard-add-button {
  background-color: #f6ebf40a;
  border-color: #4e90d2;
}

.modal-confirm-size {
  max-width: 415px;
  max-height: 252px;
}

.hand-cursor {
  cursor: pointer;
  color: #3699ff;
}

.hand-cursorAdd {
  cursor: pointer;
  color: #3699ff;
}

.hand-cursorAdd.inActive {
  cursor: not-allowed;
  opacity: 0.5;

}


.svg-icon-sm-new svg {
  height: 1rem;
}

.my-profile-card-width {
  max-width: 840px;
}

.cardsFont {
  font-size: 3.6875rem !important; // for 59px
}

.textFont {
  font-size: 1rem; // for 16px
}

.textfontEighteen {
  font-size: 1.125rem; // for 18px
}

@media screen and (min-width: 996px) and (max-width:1300px) {

  .reportFont {
    font-size: 1.1rem; // for 20px
  }

  .textFont {
    font-size: 0.9rem; // for 16px
  }
}

.fontDocument {
  font-size: 1.125rem !important; //for 18px
  font-weight: bold !important;
}

.tabledHeadFont {
  min-width: 10.375rem !important; // for 150px
}

.tabledHeadFontfive {
  min-width: 7.25rem !important;
}

.accessButtonFont {
  font-weight: 500 !important;
}

.secondaryUserFont {
  font-size: 1.5rem !important; // for 24px
  font-weight: 600;
}

.delButton {
  font-size: 0.9375rem !important; // for 15px
  font-weight: 500;
}

.deactive .ng-otp-input-wrapper {
  padding: initial;
  padding-top: 0rem !important;
}

.verifyButton {
  display: block;
  width: 73%;
}

.deactivateFont {
  font-size: 0.75rem !important;
}

.warnicon svg {
  height: 3rem !important;
  width: 3rem !important;
  opacity: 0.5;
}

.sizeof svg {
  height: 3.0rem !important;
  width: 3.0rem !important;
}

.paumentCard svg {
  height: 2.6375rem !important;
  width: 5.28125 !important;
}

.tableheadWidth {
  min-width: 12.25rem !important;
}

.gutter-bg {
  margin-bottom: 1.375rem !important;
}

.btn-defalult {
  width: 434px;
  height: 66px;
  margin: 27px 170px 191px 171px;
  padding: 21px 121px;
  border-radius: 8px;
  background-color: #32e875;
}

.ng-select .ng-clear-wrapper {
  padding-top: 5px !important;
  cursor: pointer;
  position: relative;
  width: 17px;
  -webkit-user-select: none;
  user-select: none;
}

.svg-icon-arrow-down svg {
  width: 0.9rem !important;
  height: 0.9rem !important;
  margin-left: 0.1rem;

  path {
    fill: #404244;
  }
}

.critical-field-popover {
  .popover-body {
    min-width: 120px !important;
  }
}